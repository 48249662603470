<div class="container-fluid">
  <div class="row">
    <div class="col-md-8">
      <img class="img-fluid"  src="assets/mtee_slideshow_03.jpg">
      <h2>{{homeComponentItems.mtee_app_tagline}}</h2>
      <p>{{homeComponentItems.mtee_funding_messaging}}</p>

      <!-- Header -->
      <header class="bg-primary py-5 mb-5">
        <div class="container h-100">
          <div class="row h-100 align-items-center">
            <div class="col-lg-12">
              <h1 class="display-4 text-white mt-5 mb-2"> {{homeComponentItems.tabcontent}}</h1>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div class="col-md-4 mb-5">
      <app-log-in></app-log-in>
   <!--   <app-log-in *ngIf=" this.cookieService.get('UserId') == '' || this.cookieService.get('UserId') == '-' "></app-log-in>
      <hr>--> 
      <app-signup *ngIf=" idd == 'login'"></app-signup>
     
    </div>
  </div>
  <!-- /.row -->


  <!-- /.row -->

</div>
