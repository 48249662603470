import {Component, OnInit, ViewChildren, QueryList, ElementRef, Output, EventEmitter} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { WebserviceCallerService } from '../webservice-caller.service';
import { DataShareService } from '../data-share.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker, MatDatepickerInputEvent} from '@angular/material/datepicker';
import * as fileSaver from 'file-saver';
import { saveAs } from 'file-saver';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  startDate =  new Date();
  endDate = new Date();
  formReport: FormGroup;
  errorMsg = '';
  reportDate = new Date();
  reportKey: 'Mittaristo@2021';
  mtee_campaign_code: ' ';
  public dfile: [];
  //
  public keyVerificationRequest = {
    key: ''
  };
  //
  public formReportRequest = {
    startDate: new Date(),
    endDate: new Date(),
    report_key: undefined,
    mtee_campaign_code: undefined
  };
  //
  minStartDate: Date;
  minEndDate: Date;
  maxStartDate: Date;
  maxEndDate: Date;
  //
  //
  //
  isPanelDisabled: boolean;
  isPanelExpanded: boolean;
  showPanel = false;
  //
  selectedStartDate(event: MatDatepickerInputEvent<Date>) {
    this.startDate = new Date(event.value);
    this.endDate.setHours(0, 0, 0 );
    console.log('---------------- : ' + this.startDate);
    this.formReportRequest.startDate = this.startDate;
  }
  //
  selectedEndDate(event: MatDatepickerInputEvent<Date>) {
    this.endDate = new Date(event.value);
    this.endDate.setHours(23, 59, 59 );
    console.log('---------------- : ' + this.endDate);
    this.formReportRequest.endDate = this.endDate;
  }
  //
  constructor(private formBuilder: FormBuilder, public rest: WebserviceCallerService, private router: Router,
              private dataBus: DataShareService) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDate = new Date().getDay();
    this.minStartDate = new Date(2020, 0, 1);
    this.maxStartDate = new Date();
    this.minEndDate = new Date(2020, 0, 1);
    this.maxEndDate = new Date();
  }

  ngOnInit() {
    this.formReport = this.formBuilder.group({
      reportKey: [null, Validators.required],
      mtee_campaign_code: [null, Validators.compose([Validators.minLength(2), Validators.maxLength(8)])]
    });
    this.formReportRequest.report_key = this.reportKey;
    this.isPanelDisabled = true;
    this.isPanelExpanded = false;
  }
  //
  downloadData() {
    // console.log('Inside downloadData');
    this.formReportRequest.report_key = this.reportKey;
    this.formReportRequest.mtee_campaign_code = this.formReport.value.mtee_campaign_code;
    // console.log(this.formReportRequest);
    this.rest.getDownloadData(this.formReportRequest)
      .subscribe(response => {
        console.log('Inside Download File after response');
        const fileName = 'Mtee2_' + this.startDate + '_' + this.endDate + '.csv';
        fileSaver.saveAs(response, fileName);
        console.log('File Downloaded');
        //
        /*this.dfile = JSON.parse(JSON.stringify(response.result));
        // tslint:disable-next-line:prefer-const
        const options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: false,
          title: 'Download Request',
          useBom: true,
          noDownload: false
        };
        // tslint:disable-next-line:no-unused-expression
        new ngxCsv(this.dfile, 'DownloadFile', options);*/
        //
        this.isPanelDisabled = true;
        this.isPanelExpanded = false;
      });
  }
  //
  verifyKey() {
    this.keyVerificationRequest.key = this.reportKey;
    this.rest.verifyReportKey(this.keyVerificationRequest).subscribe((data: {}) => {
      if (this.reportKey === data) {
        this.isPanelDisabled = false;
        this.isPanelExpanded = true;
        this.showPanel = true

      }
    });
  }



}
