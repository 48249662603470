<div class="login-form jumbotron">

  <form id="formLogin" [formGroup]="formLogin">

    <h4 class="card-title text-center">{{loginComponentItems.mtee_login_tigline}}</h4> 
 <!--   <h4 class="card-title text-center">Click start button below to start survey questions</h4>-->

    <div class="jumbotron-fluid">

      <div class="form-group">
        <input type="hidden" class="form-control" id="mtee_user_id" formControlName="mtee_user_id"  >
      </div>

      <div class="form-group">
        <input type="hidden" class="form-control" id="mtee_user_pwd"  formControlName="mtee_user_pwd" >
      </div>

      <div class="form-group" *ngIf=" idd == 'login'" >
        <input type="text" class="form-control" id="mtee_user_id" formControlName="mtee_user_id"  >
      </div>

      <div class="form-group" *ngIf=" idd == 'login'">
        <input type="password" class="form-control" id="mtee_user_pwd"  formControlName="mtee_user_pwd" >
      </div>

      <div class="form-group">
        <button type="submit" class="btn action-button btn-block" (click)="loginUser()">{{loginComponentItems.mtee_login_btn}}</button>
      
      </div>

    </div>

   <div class="clearfix">
          </div>
    
  </form>

   
</div>
